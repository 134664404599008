import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
const env = import.meta.env;
if (env.VITE_MODE === 'production') {
    console.log = () => { };
} else if (env.VITE_MODE === 'test') {
    console.log = (args: unknown, level?: number) => {
        if (level === 1) {
            console.info(args);
        }
    };
}
ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
