const Loader = () => {
  return (
    <div className="flex w-full h-full items-center justify-center bg-gray-300">
      <div>
        <img src="images/Logo.svg" alt="Logo" width={128} />
      </div>
    </div>
  );
};

export default Loader;
