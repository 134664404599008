import { Suspense } from "react";
import Loader from "./components/loader";
import Suspend from "./components/suspend";
import { loadAppConfig } from "./config/config";
import { IndexedDBTrackService } from "./services/trackCache";
import { trackServiceSignal } from "./signals/trackServiceSignal";

function App() {
  window.appConfig = loadAppConfig();
  trackServiceSignal.value = new IndexedDBTrackService();
  

  return (
    <Suspense fallback={<Loader />}>
      <Suspend />
    </Suspense>
  );
}

export default App;
