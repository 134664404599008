import { AppConfig } from "../models/interfaces";

export const loadAppConfig = (): AppConfig => {
  const globalConfig = window.rawconfig;
  return {
    baseMap: globalConfig.VITE_BASEMAP,
    mapService: globalConfig.VITE_MAPSERVICE,
    mapLayers: globalConfig.VITE_MAP_LAYERS,
    stationsLayer: globalConfig.VITE_MAP_STATIONS_LAYER,
    apiRoot: globalConfig.VITE_API_ROOT,
    tokenPath: globalConfig.VITE_TOKEN_PATH,
    ongoingPath: globalConfig.VITE_ONGOING_PATH,
    arrivedPath: globalConfig.VITE_ARRIVED_PATH,
    localStoragePathsPrefix: globalConfig.VITE_LOCALSTORAGE_PATHS_PREFIX,
    infoboxIntervalHours: globalConfig.VITE_INFOBOX_INTERVAL_HOURS,
    websocketUrl: globalConfig.VITE_WEBSOCKET_URI,
    websocketReconnectMax: globalConfig.VITE_WEBSOCKET_RECONNECT_MAX,
    websocketReconnectDelay: globalConfig.VITE_WEBSOCKET_RECONNECT_DELAY,
    routingDataFiles: globalConfig.VITE_ROUTING_DATA_FILES.split(","),
    routingDataFilesDate: globalConfig.VITE_ROUTING_DATA_FILES_DATE,
    s3Bucket: globalConfig.VITE_S3_BUCKET,
  };
};
export const appConfig = (): AppConfig => window.appConfig;

